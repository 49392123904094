const { required, minLength, email } =  require('vuelidate/lib/validators');
import {validationMixin} from "vuelidate/src";
import { TheMask } from 'vue-the-mask'
import xhr from '@/services/httpService'

export default {
    name: 'appContact',
    mixins: [validationMixin],
    components: {TheMask},
    data() {
        return {
            recaptcha: {
                siteKey: process.env.VUE_APP_RECAPTCHA_KEY,
                widgetId: 0
            },
            user: {
                name: '',
                email: '',
                phone: '',
                message: '',
                captcha: '',
                service: process.env.VUE_APP_SOURCE
            },
            error: false
        }
    },
    validations: {
        user: {
            name: {
                required,
                minLength(username) {
                    return username.length >= 4;
                },
            },
            email: { required, email },
            phone: { required },
            message: {  }
        }
    },
    methods: {
        submit: async function () {
            try {
                grecaptcha.ready(() => {
                    grecaptcha.execute(process.env.VUE_APP_CAPTCHA_KEY, {action: 'submit'}).then( async token => {
                        this.user.captcha = token;
                        await xhr('POST', '/api/index/contact', this.user)
                        window.location.href = "https://ferloclean.com/thank-you"
                    });
                });
            } catch (e) {
                this.error = true;
            }
        },
    }
}
