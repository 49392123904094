export default function(method, path, data) {
    return new Promise(async function(resolve, reject) {
        try {
            let xhr = new XMLHttpRequest();
            let lang = window.localStorage.getItem('lang');
            xhr.open(method, `${process.env.VUE_APP_API_URL}${path}`)
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('Accept-Language', lang || 'es_MX');
            xhr.setRequestHeader('Public-Key', process.env.VUE_APP_PUBLIC_KEY);
            xhr.timeout = 5000;

            xhr.onload = function () {
                if (this.status >= 200 && this.status <= 300) {
                    resolve(true);
                    return false;
                }
                reject(null)
            };
            xhr.onerror = function () {reject(null)};
            xhr.ontimeout = function () {reject(null)};
            xhr.send(JSON.stringify(data))
        } catch (error) {
            reject(null)
        }
    });
}